import React from 'react'
import PageWrapper from "../components/PageWrapper";
import Rte from "../sections/landing/popular";
import ACt from '../sections/nonrhino/nonrhinocontent';
import Head from "../components/head";
import { JsonLd } from '../components/JsonLd';
import Nongallery from "../sections/nonrhino/nonrhinogallery.js";
import Lan from "../sections/nonrhino/nonrhinohero";
import Testimonial from "../sections/rhino/revrhino.js";
import Liquid from "../sections/nonrhino/versus.js";
import Saveface from "../sections/landing/saveface";
import Pro from '../sections/landing/steps.js';
import Acco from "../sections/nonrhino/nonrhinofaq";
import Videos from "../sections/non/video.js";


const NonsurgicalRhinoPage = () => ( 
  <>
 <Head title="Non Surgical Rhinoplasty in London" image="/images/dermamina-home.jpg"  description="Balance or straighten your nose in a same-day appointment. A non-surgical rhinoplasty using dermal fillers is a safe, and non-invasive treatment. " keywords="Nose jobs, Liquid nose job, Liquid rhinoplasty, non Surgical nose job London, Nose filler, Non surgical rhinoplasty, Non surgical nose job, Pain free nose job, non surgical rhinoplasty London, non surgical nose job harley street"/>

<JsonLd>
{{
'@context': 'https://schema.org',
'@type': 'Organization',
'@id': 'https://www.dermamina.com/#organization',
url: 'https://www.dermamina.com',
name: 'Dermamina',
contactPoint: {
  '@type': 'ContactPoint',
  telephone: '020 7099 6650',
  contactType: 'Customer service',
},
address:{
  '@type': 'PostalAddress',
streetAddress: '114 New Cavendish Street',
addressRegion:'London',
postalCode:'W1W 6XT',
addressLocality:'Westminster',
},
image: {
  '@type': 'ImageObject',
  url: 'https://dermamina.com/images/dermamina-home.jpg',   
},
logo: {
'@type': 'ImageObject',
url: 'https://dermamina.com/images/dermamina-logo.svg',   
width: '200',
},
'name': 'dermamina',
'sameAs': [

'https://www.facebook.com/DermaMina-1985252831708400/',
'https://www.instagram.com/dermamina',
],
}}
</JsonLd>

<JsonLd>
{{
'@context': 'https://schema.org',
'@type': 'BreadcrumbList',
'itemListElement': [
  {'@type': 'ListItem', 
position:1,
name:'Dermamina Clinic',
item:'https://www.dermamina.com',
},

{'@type': 'ListItem', 
position:2,
name:'Non-Surgical Rhinoplasty',
item:'https://www.dermamina.com/non-surgical-nose-job',
},

{'@type': 'ListItem', 
position:3,
name:'Tear Trough Fillers Under Eye',
item:'https://www.dermamina.com/tear-trough-under-eye',
},

]
}}
</JsonLd>
      <PageWrapper headerDark footerDark>
      <Lan />
      <Nongallery />
      <ACt />
  
      <Testimonial />
      
      <Liquid />
   
  <Acco />
  
   <Rte />

 
   <Saveface />
 
     </PageWrapper>
 
  </>
)
export default NonsurgicalRhinoPage
